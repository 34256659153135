// If we're going to override Bulma variables, we need to do it before importing Bulma modules
// So in our styles.scss file, we should import this file before importing Bulma and/or its modules

/* Imports necessary to modify bulma variables */
@import "node_modules/bulma/sass/utilities/initial-variables",
"node_modules/bulma/sass/utilities/functions";
/* Override initial variables default here */
// Add a serif family
$family-serif: "Montserrat",
sans-serif;
$primary: #ae4a84;
$link: #122d64;
$background-color: white;
$my-grey: #d8d8d8;
$control-radius: 0px;
$input-radius: 0px;
/* End override initial variables */


/* Import necessary to modify bulma derived variables */
@import "node_modules/bulma/sass/utilities/derived-variables";
/* Override derived variables default here */
/* End override derived variables */


/* Set project specific SCSS variables */
$spc0: 0.5em;
$spc1: 1em;
$spc2: 2em;
$spc3: 2.5em;

$rspc0: 0.5rem;
$rspc1: 1rem;
$rspc2: 2rem;

@mixin regular-padding {
    padding: $spc2;
}

@mixin border-radius {
    border-radius: 10px 10px 10px 10px;
}

@mixin shadow {
    box-shadow: 2px 3px 7px -4px rgba(0, 0, 0, 0.75);
}

$control-width: 1024px;
$nav-easing: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

$regular-font-size: 0.8rem;
$regular-line-height: 2.5;

@mixin curved {
    border-radius: 0.2em;
}

@mixin remove-click-highligh {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}